<template>
  <div class="index">
    <div class="content">
      <div class="about">
        <div class="about-left">
          <img
            src="../../assets/image/about.png"
            alt=""
          >
        </div>
        <div class="about-right">
          <div class="text">
            深圳市科创融资担保有限公司（原深圳市科创融资担保有限公司，以下简称“我司”）是经国家有关主管部门批准，成立于2007年8月16日，现公司注册资本为人民币20,000万元。我司是一家综合性融资担保公司，主要经营融资性和非融资性担保业务，重点对深圳市中小企业、通信设备/计算机及其他电子设备制造业，科技化、规模化的电子产品和加工小企业，信息传输及计算机各产业链大中型核心企业配套的小企业，背靠优质大股东的小企业，拥有自主知识产权、在技术、产品、销售方面均有一定成熟度等企业进行融资担保服务。
            2011年3月31日，我司按《深圳市融资性担保公司规范整顿工作方案》规范整顿后，成为第一批成功获取由广东省人民政府金融工作办公室颁发的『融资性担保机构经营许可证』行业资质证书。
            我司目前主要服务的业务品种有：公司类担保业务、个人类担保业务、工程类担保业务、司法类担保业务等。业务范围涵盖了企业融资担保、消费及投资担保、房地产交易担保、履约担保、信用担保等多个领域。与国内多家银行和中介机构建立了良好、稳定的合作关系。
            我司以担保业务为基础业务，规范运作，严格控制风险，以灵活的业务方式，完全依据市场及客户的需求提供系统专业化的融资担保服务；以专业、诚信、稳健、创新为经营理念，专业为中小企业及个人创业提供优质、高效、专业的商业担保及增值服务等一体化金融服务。
          </div>
          <!-- <a-button
            type="link"
            @click="$router.push('./brandintroduction')"
          >
            查看更多
          </a-button> -->
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {

    };
  },
  mounted() {
  },
};
</script>
<style lang="less" scoped>
.index{
    display: flex;
    flex-direction: column;
    height: 780px;
    background-color: #f2f2f2;
}
.content {
  width: 1200px;
  height: 700px;
  margin: 0 auto;
  background: #ffff;
  margin-top: 50px;
}
.about {
    display: flex;
    justify-content: center;
    padding-top: 60px;
    .about-left {
      display: flex;
      justify-content: center;
      align-items: center;
      img {
      }
    }
    .about-right {
      position: relative;
      .text {
        text-align:justify;
        margin-left: 100px;
        width: 670px;
        text-indent: 2em;
        font-size: 16px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #666;
        line-height: 36px;
      }
      ::v-deep .ant-btn {
        position: absolute;
        right: 0;
        bottom: 0;
        width: 96px;
        height: 40px;
        border: 1px solid #ddd;
        border-radius: 20px;
        font-size: 0.875rem;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #999;
        background-color: #fff;
        cursor: pointer;
        transition: all 0.5s;
      }
    }
  }
</style>
